import { MusicaBox, MusicaInformacaoBox } from "./style";

export default function CardMusica({clickModal,...props}){
    return (
        <MusicaBox onClick={clickModal}>
            <small>{props.informacoes.indice+1 + "."}</small>
            <MusicaInformacaoBox>
                <small>{props.informacoes.musicas.nomeMusica}</small>
                <span>{props.informacoes.musicas.artista}</span>
            </MusicaInformacaoBox>
            <small>{props.informacoes.musicas.tom}</small>
        </MusicaBox>
    )
}