import Louvor from './Views/Louvor/Louvor';
import Midias from './Views/Midias/Midias';
import NotFound from './Views/NotFound/NotFound';
import { Routes, Route } from "react-router-dom";
import './App.css'
import EscalaIndividual from './Views/EscalaIndividual/EscalaIndiviual';
import Home from './Views/Home/Home';
import EdicaoLouvor from './Views/EdicaoLouvor/EdicaoLouvor';
import EdicaoMidias from './Views/EdicaoMidias/EdicaoMidias';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/Louvor" element={<Louvor />} />
          <Route path="/EditarLouvor" element={<EdicaoLouvor />} />
          <Route path="/EditarMidias" element={<EdicaoMidias />} />
          <Route path="/Midias" element={<Midias />} />
          <Route path="/Pessoal" element={<EscalaIndividual />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

    </div>
  );
}

export default App;
