import './PageHeader.css'
import LogoIcf from '../LogoIcf/LogoIcf'

export default function dv_container_Escala(){
    return(
        <div id="dv_Header">
            <h1 className="tx_Header">Equipes Família Capão</h1>
            <LogoIcf height={"4vh"} fillColor={"#fff"}/>
        </div>
    )
}