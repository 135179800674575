import { IndividualBox, DateBox, FuncaBox, FuncaGroupBox } from "./style"

export default function CardIndividual(props){

    const data = props

    let dateService = new Date(data.props.dados.servico.data)

    const ExtractMonthName = () => {
        var monthName = (dateService.toLocaleDateString('pt-BR',  { month: 'long' }))
        var capitalize = monthName[0].toUpperCase()
        return  capitalize + monthName.substring(1,3)
    }

    const GenerateRandomHeigth = (countFuncoes) => 
        countFuncoes >= 2 ? "300px" : GetRandom(200, 280)+ "px"

    const GetRandom = (min, max) => 
        Math.floor(Math.random() * (max - min)) + min;

    const handleClick = () => {
        data.props.setIdClicado(data.props.dados.servico.data)
        data.props.setCorBotao(data.props.color)
    }

    return(
        <IndividualBox 
            height={GenerateRandomHeigth(data.props.dados.formacao.length)} 
            color={data.props.color}
            onClick={handleClick}
            >
            <DateBox>
                <span>{dateService.getDate()}</span>
                <span>{ExtractMonthName()}</span>
            </DateBox>
            <FuncaGroupBox>
                {data.props.dados.formacao.map((formacao, i) => (
                    <FuncaBox key={i} porps={data.props.color}>
                        <img id="img_Funcao" alt={formacao.nomeFuncao} src={"https://www.icfcapao.com.br/img/fn_"+formacao.idFuncao+".png"}/>
                        <small>{formacao.nomeFuncao}</small>
                    </FuncaBox>
                ))}
            </FuncaGroupBox>

        </IndividualBox>
    )
}