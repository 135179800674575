import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from '../../services/api.js';
import { ButtonName, HeaderBox, NameBox, PresentationBox, LogoBox, LogoImg, LogoTxt, HomeBox, ButtonVersaoAntiga, FooterButtonsDiv } from "./styles";
import { ClipLoader } from "react-spinners";
import { AppColors } from "../../Styles/colors.js";
import Logo from "../../Components/LogoIcf/LogoIcf.jsx";

export default function Home() {

    const [ministros, setMinistros] = useState(null);
    const navigate = useNavigate();

    if(localStorage.getItem("ministryId") !== null)
        navigate("/pessoal")

    useEffect(() => {
      api
        .get("/Escalas/BuscarTodosMinistros")
        .then((response) => setMinistros(response.data));
    }, []);

    const setMinistroNoLocalStorage = (idNumMinistry, ministryName) =>{
        localStorage.setItem("ministryId",idNumMinistry)
        localStorage.setItem("ministryName",ministryName)
        navigate('/pessoal')
    }

    return(
        <HomeBox>
            <HeaderBox>
                <Logo className="logo" fillColor={"#ffffff"} height={"4vh"}/>
                <LogoTxt>
                    <small>Equipes </small>
                    <small>Família </small>
                    <small>Capão</small>
                </LogoTxt>
            </HeaderBox>
            <small>Escolha seu nome:</small>
            <PresentationBox>
                <NameBox>
                    {!ministros ? <ClipLoader color={AppColors.decorative.greenDark}  size={150} speedMultiplier={1}/> :
                    ministros.map((ministro, i) => (
                        <ButtonName key={i} onClick={() => setMinistroNoLocalStorage(ministro.id, ministro.nome)}>
                            {ministro.nome}
                        </ButtonName>
                    ))}
                </NameBox>
            </PresentationBox>

            <FooterButtonsDiv>
                <ButtonVersaoAntiga
                    bgColor={AppColors.decorative.greenDark} 
                    fontColor={"#fff"}
                    onClick={() => navigate('/Louvor')}>
                    Visão Geral Louvor
                </ButtonVersaoAntiga>

                <ButtonVersaoAntiga
                    bgColor={AppColors.decorative.greenDark} 
                    fontColor={"#fff"}
                    onClick={() => navigate('/Louvor')}>
                    Edição Louvor
                </ButtonVersaoAntiga>

                <ButtonVersaoAntiga 
                    bgColor={AppColors.decorative.greenDark} 
                    fontColor={"#fff"}
                    onClick={() => navigate('/Midias')}>
                    Visão Geral Mídias
                </ButtonVersaoAntiga>

                <ButtonVersaoAntiga
                    bgColor={AppColors.decorative.greenDark} 
                    fontColor={"#fff"}
                    onClick={() => navigate('/Louvor')}>
                    Edição Mídias
                </ButtonVersaoAntiga>
            </FooterButtonsDiv>
            
        </HomeBox>
    )

}