
import { ButtonBox, CamposModal, CancelarButton as SalvarButton, ModalBack, ModalDef, ModalHeader, ModalTitle } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from "@mui/material";
import { useState } from "react";

export default function ModalEscala(props){

    const [chave, setChave] = useState("")

    const Salvar = () => {
        FecharModal()
        props.valorChave.current = chave
        props.FecharModalSalvarEscala()
        props.EventoFecharModal()
    };

    const FecharModal = () => {
        setChave("")
    }
    
    return(
        <ModalBack>
            <ModalHeader>
                <ModalTitle> Salvar Alterações </ModalTitle>
                <CloseIcon onClick={FecharModal}/>
            </ModalHeader>
                <ModalDef>
                    Insira a palavra chave a liderança do ministério. A partir do cliquei no botão concluir as mudanças serão salvas no bando de dados e você poderá ver as alterações em qualquer tela ou dispositivo.
                </ModalDef>
            <CamposModal>
                <TextField
                    label="Chave" 
                    variant="outlined"
                    fullWidth 
                    value={chave}
                    onChange={(e) => setChave(e.target.value)}
                />
            </CamposModal>
            
            <ButtonBox>
                <SalvarButton onClick={Salvar}>Concluir</SalvarButton>
            </ButtonBox>
        </ModalBack>
    )
}