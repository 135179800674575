
import { ButtonBox, CamposModal, CancelarButton as SalvarButton, ModalBack, ModalDef, ModalHeader, ModalTitle } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from "@mui/material";
import { useState } from "react";

export default function ModalMusica(props){

    const [titulo, setTitulo] = useState(props.servico.dados[props.servico.indiceMusica].nomeMusica)
    const [artista, setArtista] = useState(props.servico.dados[props.servico.indiceMusica].artista)
    const [tom, setTom] = useState(props.servico.dados[props.servico.indiceMusica].tom)

    const Salvar = () => {
        let dados = props.servico.dados
        dados[props.servico.indiceMusica]["nomeMusica"] = titulo
        dados[props.servico.indiceMusica]["artista"] = artista
        dados[props.servico.indiceMusica]["tom"] = tom
        props.servico.setter(dados)
        FecharModal()
    };

    const FecharModal = () => {
        props.servico.closeModal()
    }
    
    return(
        <ModalBack>
            <ModalHeader>
                <ModalTitle> Editar Música </ModalTitle>
                <CloseIcon onClick={FecharModal}/>
            </ModalHeader>
            <ModalDef>
            Todas as mudanças serão refletidas em tela, mas só serão gravadas no banco de dados ao confirmar todas as alterações.
            </ModalDef>
            <CamposModal>

                <TextField
                    label="Titulo" 
                    variant="outlined"
                    fullWidth 
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                />
                <TextField
                    label="Artista" 
                    variant="outlined"
                    fullWidth 
                    value={artista}
                    onChange={(e) => setArtista(e.target.value)}
                />
                <TextField
                    label="Tom" 
                    variant="outlined"
                    fullWidth 
                    value={tom}
                    onChange={(e) => setTom(e.target.value)}
                />
            </CamposModal>
            
            <ButtonBox>
                <SalvarButton onClick={Salvar}>Concluir</SalvarButton>
            </ButtonBox>
        </ModalBack>
    )
}