
import { ButtonBox, CamposModal, CancelarButton as SalvarButton, ModalBack, ModalDef, ModalHeader, ModalTitle, SelectFuncao } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";

export default function ModalFuncao(props){
    const [ministro, setMinistro] = useState(props.servico.dados[props.servico.indiceFuncao].idMinistro)
    const [funcao, setFuncao] = useState(props.servico.dados[props.servico.indiceFuncao].idFuncao)

    const Salvar = () => {
        let dados = props.servico.dados
        dados[props.servico.indiceFuncao].nomeMinistro = props.ministros.filter(m => m.id === ministro)[0].nome
        dados[props.servico.indiceFuncao].idMinistro = ministro
        dados[props.servico.indiceFuncao].nomeFuncao = props.funcoes.filter(m => m.id === funcao)[0].descricao
        dados[props.servico.indiceFuncao].idFuncao = funcao
        props.servico.setter(dados)
        FecharModal()
    };

    const FecharModal = () => {
        props.servico.closeModal()
    }

    return(
        <ModalBack>
            <ModalHeader>
                <ModalTitle> Editar Função </ModalTitle>
                <CloseIcon onClick={FecharModal}/>
            </ModalHeader>
            <ModalDef>
            Todas as mudanças serão refletidas em tela, mas só serão gravadas no banco de dados ao confirmar todas as alterações.
            </ModalDef>
            <CamposModal>

            <FormControl fullWidth >
                <InputLabel>Ministro</InputLabel>
                <Select
                    labelId="test-select-label"
                    label="Ministro" 
                    variant="outlined"
                    fullWidth 
                    value={ministro}
                    onChange={(e) => setMinistro(e.target.value)}
                >
                    {props.ministros.map((ministro, i) => {
                        return <MenuItem value={ministro.id} key={i}>{ministro.nome}</MenuItem>                        
                    })}

                <MenuItem value={"Novo"}></MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth >
                <InputLabel>Função</InputLabel>
                <Select
                    label="Funcao" 
                    variant="outlined"
                    fullWidth 
                    value={funcao}
                    onChange={(e) => setFuncao(e.target.value)}
                >
                    {props.funcoes.map((funcao, i) =>{
                        return <MenuItem value={funcao.id} key={i}>{funcao.descricao}</MenuItem>
                    })}
                </Select>
            </FormControl>
            </CamposModal>
            
            <ButtonBox>
                <SalvarButton onClick={Salvar}>Concluir</SalvarButton>
            </ButtonBox>
        </ModalBack>
    )
}
