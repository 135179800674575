import styled from "styled-components"
import {AppColors} from "../../Styles/colors"

export const IndividualBox = styled.div`
    padding: 5% 8%;
    display: flex;
    flex-direction: column;
    gap: 2vh;

    .container{
        margin:0;
    }

    @media (min-width: 768px) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;  
    }
`

export const BoxEscala = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 10vw;
`

export const NameBox = styled.div`
    font-size: 3.5em;
    color: #db9161;
    font-weight: 600;
    line-height: 100%;
`

export const TextBox = styled.div`
    font-size: 1.2em;
    color: #db9161;
    font-weight: 400;
    color: #878787;
`

export const ButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15vw;
    margin-top: 2vh;


    font-weight: 600;
    text-decoration:none;
    a:link { text-decoration: none; color:#c5c5c5}
    a:visited { text-decoration: none; color:#c5c5c5}
    a:hover { text-decoration: none; color:#c5c5c5}
    a:active { text-decoration: none; color:#c5c5c5}

`

export const ButtonVersion = styled.div`
    display: flex;
    color: #848484;
    flex-direction: row;
    font-weight: 600;
`

export const BotaoVoltar= styled.button`
    display:flex;
    border: none;
    flex-direction: row;
    align-items: center;
    gap: 3vw;
    font-size: 1.3em;   
    margin-bottom: 4vh;
    font-weight: 400;
    color: #878787;
    text-decoration:none;
    background-color: transparent;
    a:link { text-decoration: none; color:#878787}
    a:visited { text-decoration: none; color:#878787}
    a:hover { text-decoration: none; color:#878787}
    a:active { text-decoration: none; color:#878787}
`

export const VoltarIcone = styled.div`
    border: none;
    background-color: ${AppColors.decorative.orangeLight};
    border-radius: 15px;
    filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.2));
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
`

