import styled from "styled-components";

export const ContainerEscalas = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vh;
    
    @media (min-width: 768px) {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;  
    }

`
export const ButtonModo = styled.div`
    display: flex;
    color: ${(props) => props.ativo?  "#373737" : "#848484" };
    flex-direction: row;
    font-weight: ${(props) => props.ativo? 600 : 500};
    cursor: pointer;
`