import styled from "styled-components";
import { AppColors } from "../../Styles/colors";

export const NovaEscalaBox = styled.div`
    background-color: ${AppColors.decorative.brownLight};
    min-width: 90%;
    padding: 1vh 2vw;
    margin: 1vh 2vw;
    border-radius: 10px;
    box-shadow: 5px 5px 10px -3px rgba(0,0,0,0.3); 
`