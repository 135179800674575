import dayjs from "dayjs"
import BotaoAdicao from "../BotaoAdicaoArrays/BotaoAdicao"
import { NovaEscalaBox } from "./style"

export default function BotoaEscala(props){

    const escalaBase ={
        "playlist": [
            {
                "plataformaId": 1,
                "link": ""
            },
            {
                "plataformaId": 2,
                "link": ""
            }
        ],
        "musicas": [
            {
                "nomeMusica": "Nova",
                "artista": "Novo",
                "tom": "C"
            },
        ],
        "servico": {
            "descricao": "Novo Evento",
            "data": dayjs()
        },
        "formacao": [
            {
                "nomeMinistro": "Novo",
                "nomeFuncao": "Voz",
                "idFuncao": 1
            }
        ]
    }

    const AddNovaEscala = () =>{
        props.setter([...props.dados, escalaBase])
    }

    return(
        <NovaEscalaBox onClick={AddNovaEscala}>
            <BotaoAdicao clickEvent={AddNovaEscala}/>
        </NovaEscalaBox>
    )
}