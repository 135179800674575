import { useState, useEffect } from "react";
import { NameBox, TextBox, IndividualBox, VoltarIcone, BotaoVoltar, ButtonVersion, ButtonBox } from "../EscalaIndividual/style";
import { AppColors } from "../../Styles/colors";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import api from "../../services/api";
import ClipLoader from "react-spinners/ClipLoader";
import NovoCardLouvor from "../../Components/NovoCardLouvor/NovoCardLouvor";
import { SetBoxColor } from "../../Utils/setBoxColor"
import { ButtonModo, ContainerEscalas } from "./style";
import axios from "axios";
import BotoaEscala from "../../Components/BotaoAdicaoEscala/BotaoEscala";

export default function EdicaoLouvor(){

    const [dados, setDados] = useState(null)
    const [novosDados, setNovosDados] = useState([])
    const [funcoes, setFuncoes] = useState(null)
    const [ministros, setMinistros] = useState(null)
    const [componenteEscalas, setComponenteEscalas] = useState()
    const [componenteCriacaoEscalas, setComponenteCriacaoEscalas] = useState()
    const [modoEdicao, setModoEdicao] = useState(true)
    const navigate = useNavigate();

    let Dt_Today = new Date();
    Dt_Today.setDate(Dt_Today.getDate() - 1);

    const fecthData = () =>{
        const todasProximasEscalas = "/Escalas/TodasFuturasEscalas?IdMinisterio=1&DataCorte="+Dt_Today.toISOString()
        const todosMinistros = "/Escalas/BuscarTodasFuncoes?IdMinisterio=1"
        const todasFuncoesLouvor = "/Escalas/BuscarTodosMinistros"
      
        const escalasGet = api.get(todasProximasEscalas)
        const ministrosGet = api.get(todosMinistros)
        const funcoesGet = api.get(todasFuncoesLouvor)
      
        axios.all([escalasGet, ministrosGet, funcoesGet]).then(
          axios.spread((...allData) => {
            setFuncoes(allData[1].data)
            setMinistros(allData[2].data)
            setDados(allData[0].data)
          })
        )
    }

    const AddEscalas = () =>{
        setComponenteEscalas(!dados ? <ClipLoader color={AppColors.decorative.orangeLight} size={150} speedMultiplier={1}/> :   
            dados?.map((escala, i) => {       
                return <NovoCardLouvor
                informacoes = {{color: SetBoxColor(i), dados: escala, modoEdicao:true}}
                funcoesLouvor = {funcoes}
                todosMinistros = {ministros}
                key={i}
                />   
            }))  
    }

    const AddNovasEscalas = () =>{
        setComponenteCriacaoEscalas(   
            novosDados?.map((escala, i) => {       
                return <NovoCardLouvor
                informacoes = {{color: SetBoxColor(i), dados: escala, modoEdicao:true}}
                funcoesLouvor = {funcoes}
                todosMinistros = {ministros}
                key={i}
                />   
            })
        )  
    }
    
    const HandleClickMenuCriacao = () => {
        if(dados) setModoEdicao((!modoEdicao))
    }

    useEffect(() => {
        AddEscalas()
        AddNovasEscalas()
    }, [dados, novosDados])

    useEffect(() => {
        fecthData()
      }, []);

    const Voltar = () =>{
        navigate(-1)
    }

    return (
        <IndividualBox>             
            <BotaoVoltar onClick={() => Voltar()}> 
                <VoltarIcone>
                    <ArrowBackIosIcon style={{ color: 'white' }} />
                </VoltarIcone>
                Voltar
            </BotaoVoltar>
            <NameBox>
                Olá, <br />
                Líder
            </NameBox>
            <TextBox>
            Aqui estão os próximos dias em que é possível fazer alterações, você pode adicionar mais itens usando os botões de "+" na tela, ou alterar qualquer informação vigente clicando naquela que deseja alterar. Aguarde o término do carregamento para poder acessar os menus abaixo.
            </TextBox>
            <ButtonBox>
                <ButtonModo ativo={modoEdicao} onClick={() => setModoEdicao((!modoEdicao))}>EDITAR</ButtonModo>
                <ButtonModo ativo={(!modoEdicao)} onClick={HandleClickMenuCriacao}>CRIAR</ButtonModo>
            </ButtonBox>
            {!modoEdicao && <ContainerEscalas>
                <BotoaEscala setter={setNovosDados} dados={novosDados} />
                {componenteCriacaoEscalas}
            </ContainerEscalas>}
            {modoEdicao && <ContainerEscalas>
                {componenteEscalas}
            </ContainerEscalas>}
        </IndividualBox>
    )
}