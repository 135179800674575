import styled from "styled-components";

export const FuncaoBox = styled.div`
    display: flex;
    flex-direction: row;
    color: #fff;
    align-items: center;
    gap 2vw;
    padding: 2vh 4vw;
    max-width: 50%;
    
    & img{
        max-width: 70%;
        opacity: 0.7;
    }
`

export const IconeBox = styled.div`
    border-radius: 50%;
    overflow: hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .30);
    width: 31px;
    height: 30px;
`

export const FuncaoDescritiveBox = styled.div`
    display: flex;
    flex-direction: column;

    & span{
        font-size: 0.9em;
        font-weight: 500;
    }

    & small{
        font-size: 0.7em;
        font-weight: 300;
    }
`