import styled from "styled-components";
import { AppColors } from "../../Styles/colors";

export const MusicaBox = styled.div`
    background-color: rgba(0, 0, 0, .20);
    display: flex;
    flex-direction: row;
    font-size: 0.9em;
    color: white;
    gap: 5vw;
    padding: 1.5vh 8vw;
    align-items: center;
`

export const MusicaInformacaoBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;

    & small {
        font-size: 1em;
        font-weight: 500;
    }

`

