import styled from "styled-components";
import { AppColors } from "../../Styles/colors";

export const HomeBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ustify-content? space-between;
    height: 100vh;

    @media (min-width: 768px) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;  
    }
`

export const PresentationBox = styled.div`
    max-width: 90%;
    color: ${AppColors.base.fontColor};
    padding: 10px;
    border-radius: 10px;
    margin: 0;
    justify-content? space-between;
`

export const HeaderBox = styled.div`
    background-color: ${AppColors.decorative.greenLight};
    display:flex;
    flex-direction: column;
    margin-bottom: 4vh;
    font-weight: 500;
    min-width: 100vw;
    max-width: 100vw;
    color: ${AppColors.base.fontwhite};
    padding: 2vh 0 5vh 0;

    .logo{
        align-self: end;
        padding: 0 5vw;
    }

    small{
        align-items: flex-start;
        padding: 0 5vw;
        font-size: 3em;
        line-height: 1;
    }
`

export const LogoBox = styled.div`
    display:flex;
    flex-direction: row;
    max-height: 10vh;
    align-items: center;
    font-weight: 500;
    font-size: 2em;
    line-height: 30px;
    color: #fff;
    margin: 1vh 0 ;
    justify-content: center
`

export const LogoTxt = styled.div`
    display:flex;
    flex-direction: column;
`

export const LogoImg = styled.img`
    max-width: 40%;
`

export const NameBox = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap: 5px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
`

export const ButtonName = styled.button`
    border: none;
    background-color: ${AppColors.decorative.orangeLight};
    color: ${AppColors.base.fontwhite};
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 3px 6px);
    min-height: 8vh;
    padding: 0px 3vw 1vh 8vw;
    border-radius: 5px;
    font-size: 0.8em;
    font-weight: 500;
    display: flex;
    place-content: flex-end center;
    align-items: flex-end;
    text-align: end;
    justify-content: start;
    flex-direction: row;

    &:active{
        background-color: ${AppColors.decorative.greenDark};
    }
`

export const ButtonVersaoAntiga = styled.button`
    border:none;
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.fontColor};
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2));
    min-height: 12vh;
    padding: 0 5vw;
    max-width: 18%;
    border-radius: 5px;
    font-size: 0.7em;
    font-weight: 400;
    font-family: "segoe ui"
    text-align:right;
    display: flex;
    align-items: flex-end;
    padding-bottom: 1vh;

    &:active{
        background-color: ${AppColors.decorative.orangeLight};
    }
`

export const FooterButtonsDiv = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 100vw;
    -webkit-box-pack: center;
    justify-content: space-evenly;
    margin-top: 4vh;
    padding: 2vh 4vw;
    background-color: ${AppColors.decorative.greenLight};
`
