import './NotFound.css'


export default function NotFound(){
    return(
        <div id="dv_master_notfound">
            <div id="dv_Container_NotFound">
                <p id="tx_NotFound"> <b>Erro 404</b><br/> Página Não Encontrada, chama o Vini</p>
            </div>
        </div>
    )
}

