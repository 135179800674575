
import { ButtonBox, CamposModal, CancelarButton as SalvarButton, ModalBack, ModalBox, ModalDef, ModalHeader, ModalTitle } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from "@mui/material";
import { useState } from "react";

export default function ModalPlaylist(props){

    const [spotify, setSpotify] = useState(props.servico.dados[0].link)
    const [youtube, setYoutube] = useState(props.servico.dados[1].link)

    const Salvar = () => {
        let dados = props.servico.dados
        dados[0].link = spotify
        dados[1].link = youtube
        props.servico.setter(dados)
        FecharModal()
    };

    const FecharModal = () => {
        props.servico.closeModal()
    }
    
    return(
        <ModalBack>
            <ModalHeader>
                <ModalTitle> Editar Playlists </ModalTitle>
                <CloseIcon onClick={FecharModal}/>
            </ModalHeader>
                <ModalDef>
                    Todas as mudanças serão refletidas em tela, mas só serão gravadas no banco de dados ao confirmar todas as alterações.
                </ModalDef>
            <CamposModal>
                <TextField
                    label="Spotify" 
                    variant="outlined"
                    fullWidth 
                    value={spotify}
                    onChange={(e) => setSpotify(e.target.value)}
                />
                <TextField
                    label="Youtube" 
                    variant="outlined"
                    fullWidth 
                    value={youtube}
                    onChange={(e) => setYoutube(e.target.value)}
                />
            </CamposModal>
            
            <ButtonBox>
                <SalvarButton onClick={Salvar}>Concluir</SalvarButton>
            </ButtonBox>
        </ModalBack>
    )
}