import styled from "styled-components";

export const NovoCardStreamingBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 2vh 4vw;
    gap:  2vw;
`


export const StreamingButton = styled.button`
    background-color: rgba(0, 0, 0, .40);
    border-radius: 5%;
    border: none;
    min-height: 5vh;
    min-width: 8vw;
    box-shadow: 5px 5px 10px -3px rgba(0,0,0,0.40); 
    display: flex;
    justify-content: center;
    align-items: center;

    & img{
        max-width: 6vw;
        filter: invert(1);
    }

    @media (min-width: 768px) {
        min-height: 3.5vh;
        min-width: 2vw;

        & img{
            max-width: 2vw;
        }
    }
`