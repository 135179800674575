import styled from "styled-components"

export const BoxButtonAddArrays = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 20vw;
    padding: 1vh 2vw;
`

export const ButtonAddArrays = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 0.8em;
    height: 40px;
    width: 40px;
    border: none;
    background-color: rgba(0, 0, 0, .50);
    border-radius: 100%;
    box-shadow: 5px 5px 10px -3px rgba(0,0,0,0.5); 
`