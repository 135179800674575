import { Modal } from "@mui/material";
import styled from "styled-components";

export const NovoCard = styled.div`
    min-width: 100%;
    border-radius: 10px;
    background-color: ${(props) => props.color};
`

export const CloseBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 3vw;
`

export const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 500;
    font-size: 30px;
    padding: 15px;

    .tempo{
        color: rgb(255 255 255 / 60%);
        font-size: 0.7em;
    }
`

export const MusicasBox = styled.div`
    display: flex;
    flex-direction: column;
`

export const EscalacaoBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1vh;
`

export const TipoFuncaoBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    border-radius: 10px;
    margin: 0.5vh 5vw;
    background-color: rgba(0, 0, 0, .20);
`

export const ModalBox = styled(Modal)`
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
`

export const BoxButtonSalvar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vh;
`

export const ButtonSalvarEscala = styled.button`
    display: flex;
    flex-direction: row;
    color: #fff;
    font-weight: 600;
    font-size: 0.8em;
    padding: 15px;
    border: none;
    background-color: rgba(0, 0, 0, .50);
    border-radius: 5px;
    box-shadow: 5px 5px 10px -3px rgba(0,0,0,0.5); 
`
