import { AppColors } from "../Styles/colors"

const colorNameArr = [
    {colorName: 'greenLight'},
    {colorName:'purpleLight'},
    {colorName:'orangeLight'},
    {colorName:'brownLight'},
]

export function SetBoxColor(indice){
    let valor =  indice - (Math.trunc(indice/4)*4)
    return AppColors.decorative[colorNameArr[valor].colorName]
}