import { ButtonAddArrays, BoxButtonAddArrays } from "./style";
import AddIcon from '@mui/icons-material/Add';

export default function BotaoAdicao(props){
    return(
        <BoxButtonAddArrays>
            <ButtonAddArrays onClick={props.clickEvent}>
                <AddIcon/>
            </ButtonAddArrays>
        </BoxButtonAddArrays>
    )
}