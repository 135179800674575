import { useState, useEffect, useRef } from "react";
import { NameBox, TextBox, ButtonBox, ButtonVersion, IndividualBox, VoltarIcone, BotaoVoltar, BoxEscala } from "./style";
import  CardIndividual  from "../../Components/CardIndividual/CardIndividual";
import { Masonry } from '@mui/lab';
import { AppColors } from "../../Styles/colors";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import api from "../../services/api";
import ClipLoader from "react-spinners/ClipLoader";
import NovoCardLouvor from "../../Components/NovoCardLouvor/NovoCardLouvor";
import { SetBoxColor } from "../../Utils/setBoxColor"

export default function EscalaIndividual(){

    const [corEscalaEspecifica, setCorEscalaEspecifica] = useState(null)
    const [dadosEscala, setDadosEscala] = useState(null)
    const [idMinistry, setMinistry] = useState(localStorage.getItem("ministryId"))
    const [nameMinistry, setNameMinistry] = useState(localStorage.getItem("ministryName"))
    const [dataEscalaEspecifica, setDataEscalaEspecifica] = useState(null)
    const [dadosEscalaEspecifica, setDadosEscalaEspecifica] = useState(null)
    const navigate = useNavigate();

    let Dt_Today = new Date();
    Dt_Today.setDate(Dt_Today.getDate() - 1);

    useEffect(() => {
        if(dataEscalaEspecifica == null){
            setDadosEscalaEspecifica(null)
            api
            .get("Escalas/EscalasPessoais?DataCorte="+Dt_Today.toISOString()+"&IdMinistro="+idMinistry)
            .then((response) => setDadosEscala(response.data));
        }
        else{
            api
            .get("/Escalas/EscalasUnitarias?IdMinisterio=1&DataCorte="+ dataEscalaEspecifica.toString())
            .then((response) => setDadosEscalaEspecifica(response.data));
        }
    }, [dataEscalaEspecifica]);

    const Voltar = () =>{
        localStorage.removeItem("ministryId")
        localStorage.removeItem("ministryName")
        navigate(-1)
    }

    return (
        <IndividualBox>             
            <BotaoVoltar onClick={() => Voltar()}> 
                <VoltarIcone>
                    <ArrowBackIosIcon style={{ color: 'white' }} />
                </VoltarIcone>
                Voltar
            </BotaoVoltar>
            <NameBox>
                Olá, <br />
                {nameMinistry}
            </NameBox>
            <TextBox>
            Aqui estão os próximos dias em que você foi escalada(o) para servir, clique em qualquer um deles para ver detalhes.
            </TextBox>
            <ButtonBox>
                <ButtonVersion>NOVO</ButtonVersion>
                <Link to={"/Louvor"}>GERAL</Link>
            </ButtonBox>
            <BoxEscala>
                {(!dataEscalaEspecifica && !dadosEscala) && <ClipLoader color={AppColors.decorative.orangeLight} size={150} speedMultiplier={1}/>}
                {!dataEscalaEspecifica && dadosEscala &&
                    <Masonry
                        columns={2}
                        className={"container"}
                        >
                        {dadosEscala.map((escala, i) => (
                            <CardIndividual 
                                key={i} 
                                props={{color: SetBoxColor(i), dados: escala, setIdClicado: setDataEscalaEspecifica, setCorBotao: setCorEscalaEspecifica}}
                            >
                            </CardIndividual>
                        ))}
                    </Masonry>
                }
                { dataEscalaEspecifica && dadosEscalaEspecifica && <NovoCardLouvor informacoes={{color: corEscalaEspecifica, dados: dadosEscalaEspecifica[0], setDadaEspecifica: setDataEscalaEspecifica}} /> }
                
            </BoxEscala>
        </IndividualBox>
    )
}