

import { ButtonBox, CamposModal, CancelarButton as SalvarButton, ModalBack, ModalBox, ModalDef, ModalHeader, ModalTitle } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from "@mui/x-date-pickers/locales";
import { useState } from "react";
import dayjs from "dayjs";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';


export default function ModalCabecalho(props){
    dayjs.locale('pt-br');

    const [titulo, setTitulo] = useState(props.servico.dados.descricao)
    const [data, setData] = useState(dayjs(props.servico.dados.data))

    const Salvar = () => {
        let dados = props.servico.dados
        dados.data = data.toISOString()
        dados.descricao = titulo
        props.servico.setter(dados)
        FecharModal()
    };

    const FecharModal = () => {
        props.servico.closeModal()
    }
    
    return(
        <ModalBack>
            <ModalHeader>
                <ModalTitle> Editar Cabeçalho </ModalTitle>
                <CloseIcon onClick={FecharModal}/>
            </ModalHeader>
            <ModalDef>
            Todas as mudanças serão refletidas em tela, mas só serão gravadas no banco de dados ao confirmar todas as alterações.
            </ModalDef>
            <CamposModal>
                <LocalizationProvider 
                    dateAdapter={AdapterDayjs}
                >
                    <DateTimePicker  
                        label="Data"
                        slotProps={{ textField: { fullWidth: true } }}
                        ampm={false}
                        locale={ptBR}
                        value={data}
                        onChange={(e) => setData(e)}
                    />
                </LocalizationProvider >

                <TextField
                    label="Titulo" 
                    variant="outlined"
                    fullWidth 
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                />
            </CamposModal>
            
            <ButtonBox>
                <SalvarButton onClick={Salvar}>Concluir</SalvarButton>
            </ButtonBox>
        </ModalBack>
    )
}