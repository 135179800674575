import NovoCardFuncao from "../NovoCardFuncao/NovoCardFuncao";
import { CardHeader, NovoCard, CloseBox, MusicasBox, TipoFuncaoBox, EscalacaoBox, ModalBox, ButtonSalvarEscala, BoxButtonSalvar} from "./style";
import CloseIcon from '@mui/icons-material/Close';
import ModalCabecalho from "../ModalEditaCabecalho/ModalCabecalho";
import { useState } from "react";
import ModalFuncao from "../ModalEditaFuncao/ModalFuncao";
import { useEffect } from "react";
import BotaoAdicao from "../BotaoAdicaoArrays/BotaoAdicao";
import ModalEscala from "../ModalSalvaEscala/ModalEscala";
import { useRef } from "react";
import api from "../../services/api";


export default function NovoCardMidias(props){
    let dateService = new Date(props.informacoes.dados.servico.data)
    const [servico, setServico] = useState(props.informacoes.dados.servico)
    const [formacao, setFormacao] = useState(props.informacoes.dados.formacao)

    const [componenteVozes, setComponenteVozes] = useState()

    const [modalAberto, setModalAberto] = useState(false)
    const [conteudoModal, setConteudoModal] = useState()
    const chaveValidacao = useRef("")

    const ExtractMonthName = () => {
        var monthName = (dateService.toLocaleDateString('pt-BR',  { month: 'long' }))
        var capitalize = monthName[0].toUpperCase()
        return  capitalize + monthName.substring(1,3)
    }

    const handleClick = () => props.informacoes.aberto(null)

    const handleModalClose = () => {
        setModalAberto(false)
        setConteudoModal(null)
    }

    const SalvarEscala = () => {
        setConteudoModal(<ModalEscala 
            EventoFecharModal={handleModalClose}
            valorChave={chaveValidacao}
            FecharModalSalvarEscala={AcaoFecharModalSalvarEscala}
        />)
        setModalAberto(true)
    }

    const AcaoFecharModalSalvarEscala = () => {
        const CriacaoDeEscalaLouvorDTO = {
            Servico: servico,
            Escalacao: formacao,
            Ministerio: 2,
            Validacao: chaveValidacao.current,
          };

        api.post("/Criador/CriarEscala", CriacaoDeEscalaLouvorDTO)
            .then(response => {
                if (response.status === 200) {
                    alert('Escala Gravada com Sucesso');
                    window.location.reload(); 
                } else {
                    alert('Erro - Verifique a Chave!');
                }
            })
            .catch(error => {
                console.error('Erro na requisição POST:', error);
                alert('Erro na requisição POST!');
            });
    }

    const HandleClickMinistros = () => setFormacao([...formacao, {idMinistro: 0, idFuncao: 8, nomeFuncao: "Fotografia", nomeMinistro: "Novo" }])

    const AddMinistros = () =>{
        setComponenteVozes(formacao.map((funcao, i) => (
            funcao.idFuncao >= 8 && 
            <NovoCardFuncao 
                informacoes={funcao} 
                key={i}
                clickModal={() => AbrirModal({Editor: "Formacao", idFuncao: i})}
            />
        )))
    }

    useEffect(() => {
        AddMinistros()
      }, [formacao, modalAberto]);

    const AbrirModal = (param) => {
        if(props.informacoes.modoEdicao){
            setModalAberto(true)
            switch(param.Editor){
                case "Cabecalho" :
                    setConteudoModal(<ModalCabecalho 
                        servico={{dados : servico , setter : setServico, closeModal: handleModalClose}}
                    />)
                    break;
                case "Formacao" :
                    setConteudoModal(
                        <ModalFuncao
                            servico={{dados : formacao , setter : setFormacao, closeModal: handleModalClose, indiceFuncao: param.idFuncao}}
                            ministros={props.todosMinistros}
                            funcoes={props.funcoesMidias}
                        />
                    )
                    break;
            }
        }
    }

    return(
        <NovoCard color={props.informacoes.color}>
            <ModalBox 
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={modalAberto}
                onClose={handleModalClose}
                >
                    <div style={{outline:'none'}}>
                        {conteudoModal}
                    </div>
            </ModalBox>

            <CardHeader onClick={() => AbrirModal({Editor: "Cabecalho"})}>
                {props.informacoes.aberto != null && <CloseBox><CloseIcon onClick={() => handleClick()}/></CloseBox>}
                <small>{dateService.getDate()} | {ExtractMonthName(dateService)}</small>
                <small className="tempo"> às {dateService.getHours()} : {dateService.getMinutes()}</small>
                <small>{props.informacoes.dados.servico.descricao}</small>
            </CardHeader>

            <EscalacaoBox>
                <TipoFuncaoBox color={props.informacoes.color}>
                    {componenteVozes}
                    {props.informacoes.modoEdicao &&  <BotaoAdicao clickEvent={HandleClickMinistros}/>}
                </TipoFuncaoBox>
            </EscalacaoBox>

            {props.informacoes.modoEdicao && 
                <BoxButtonSalvar>
                    <ButtonSalvarEscala onClick={() => SalvarEscala()}>Salvar Escala</ButtonSalvarEscala>
                </BoxButtonSalvar>
            }

        </NovoCard>
    )
}