export const AppColors = {
    base : {
        backgoundColor: "#ebebeb",
        fontColor: "#707070",
        fontwhite: "#ffffff"
    },
    decorative : {
        greenLight: "#9bc998",
        greenDark: "#577155",
        greenMidlle: "#83aa80",
        purpleLight: "#7b778d",
        purpleLigther: "#7d7797",
        orangeLight: "#db9161",
        orangeLigther: "#d5af96",
        brownLight: "#c7a58e",
        redLight: "#db6161"
    }
}