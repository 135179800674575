import CardMusica from "../CardMusica/CardMusica";
import NovoCardFuncao from "../NovoCardFuncao/NovoCardFuncao";
import NovoCardStreaming from "../NovoCardStreaming/NovoCardStreaming";
import { CardHeader, NovoCard, CloseBox, MusicasBox, TipoFuncaoBox, EscalacaoBox, ModalBox, ButtonSalvarEscala, BoxButtonSalvar} from "./style";
import CloseIcon from '@mui/icons-material/Close';
import ModalCabecalho from "../ModalEditaCabecalho/ModalCabecalho";
import { useState } from "react";
import ModalMusica from "../ModalEditaMusica/ModalMusica";
import ModalPlaylist from "../ModalEditaPlaylist/ModalPlaylist";
import ModalFuncao from "../ModalEditaFuncao/ModalFuncao";
import { useEffect } from "react";
import BotaoAdicao from "../BotaoAdicaoArrays/BotaoAdicao";
import ModalEscala from "../ModalSalvaEscala/ModalEscala";
import { useRef } from "react";
import api from "../../services/api";


export default function NovoCardLouvor(props){

    let dateService = new Date(props.informacoes.dados.servico.data)
    const [servico, setServico] = useState(props.informacoes.dados.servico)
    const [playlist, setPlaylist] = useState(props.informacoes.dados.playlist)
    const [formacao, setFormacao] = useState(props.informacoes.dados.formacao)
    const [musicas, setMusicas] = useState(props.informacoes.dados.musicas)

    const [componenteVozes, setComponenteVozes] = useState()
    const [componenteMusicos, setComponenteMusicos] = useState()
    const [componenteMusicas, setComponenteMusicas] = useState()

    const [modalAberto, setModalAberto] = useState(false)
    const [conteudoModal, setConteudoModal] = useState()
    const chaveValidacao = useRef("")

    const ExtractMonthName = () => {
        var monthName = (dateService.toLocaleDateString('pt-BR',  { month: 'long' }))
        var capitalize = monthName[0].toUpperCase()
        return  capitalize + monthName.substring(1,3)
    }

    const handleClick = () => props.informacoes.setDadaEspecifica(null)

    const handleModalClose = () => {
        setModalAberto(false)
        setConteudoModal(null)
    }

    const SalvarEscala = () => {
        setConteudoModal(<ModalEscala 
            EventoFecharModal={handleModalClose}
            valorChave={chaveValidacao}
            FecharModalSalvarEscala={AcaoFecharModalSalvarEscala}
        />)
        setModalAberto(true)
    }

    const AcaoFecharModalSalvarEscala = () => {
        const CriacaoDeEscalaLouvorDTO = {
            Servico: servico,
            Escalacao: formacao,
            Musica: musicas,
            Playlist: playlist,
            Ministerio: 1,
            Validacao: chaveValidacao.current,
          };
          
        api.post("/Criador/CriarEscalaLouvor", CriacaoDeEscalaLouvorDTO)
            .then(response => {
                if (response.status === 200) {
                    alert('Escala Gravada com Sucesso');
                    window.location.reload(); 
                } else {
                    alert('Erro - Verifique a Chave!');
                }
            })
            .catch(error => {
                console.error('Erro na requisição POST:', error);
                alert('Erro na requisição POST!');
            });
    }

    const HandleClickVoz = () => setFormacao([...formacao, {idMinistro: 0, idFuncao: 1, nomeFuncao: "Voz", nomeMinistro: "Novo" }])
    const HandleClickMusicos = () => setFormacao([...formacao, {idMinistro: 0, idFuncao: 2, nomeFuncao: "Violão", nomeMinistro: "Novo"}])
    const HandleClickMusicas = () => setMusicas([...musicas, {"nomeMusica": "Novo","artista": "Novo","tom": "C"}])

    const AddVoz = () =>{
        setComponenteVozes(formacao.map((funcao, i) => (
            funcao.idFuncao == 1 && 
            <NovoCardFuncao 
                informacoes={funcao} 
                key={i}
                clickModal={() => AbrirModal({Editor: "Formacao", idFuncao: i})}
            />
        )))
    }

    const AddMusicos = () =>{
        setComponenteMusicos(formacao.map((funcao, i) => (
            funcao.idFuncao !== 1 && 
            <NovoCardFuncao 
                informacoes={funcao} 
                key={i}
                clickModal={() => AbrirModal({Editor: "Formacao", idFuncao: i})}
            />
        )))
    }

    const AddMusicas = () =>{
        setComponenteMusicas(musicas.map((musica, i) => (
            <CardMusica
                clickModal={() => AbrirModal({Editor: "Musica", IdMusica: i})}
                informacoes={{musicas: musica, indice: i}} 
                key={i}
            />
        )))
    }

    useEffect(() => {
        AddVoz()
        AddMusicos()
        AddMusicas()
      }, [formacao, musicas, modalAberto]);

    const AbrirModal = (param) => {
        if(props.informacoes.modoEdicao){
            setModalAberto(true)
            switch(param.Editor){
                case "Cabecalho" :
                    setConteudoModal(<ModalCabecalho 
                        servico={{dados : servico , setter : setServico, closeModal: handleModalClose}}
                    />)
                    break;
                case "Musica" :
                    setConteudoModal(
                        <ModalMusica
                            servico={{dados : musicas , setter : setMusicas, closeModal: handleModalClose, indiceMusica : param.IdMusica}}
                        />
                    )
                    break;
                case "Playlist" :
                    setConteudoModal(
                        <ModalPlaylist
                            servico={{dados : playlist , setter : setPlaylist, closeModal: handleModalClose}}
                        />
                    )
                    break;
                case "Formacao" :
                    setConteudoModal(
                        <ModalFuncao
                            servico={{dados : formacao , setter : setFormacao, closeModal: handleModalClose, indiceFuncao: param.idFuncao}}
                            ministros={props.todosMinistros}
                            funcoes={props.funcoesLouvor}
                        />
                    )
                    break;
            }
        }
    }

    return(
        <NovoCard color={props.informacoes.color}>
            <ModalBox 
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={modalAberto}
                onClose={handleModalClose}
                >
                    <div style={{outline:'none'}}>
                        {conteudoModal}
                    </div>
            </ModalBox>

            <CardHeader onClick={() => AbrirModal({Editor: "Cabecalho"})}>
                {props.informacoes.setDadaEspecifica != null && <CloseBox><CloseIcon onClick={() => handleClick()}/></CloseBox>}
                <small>{dateService.getDate()} | {ExtractMonthName(dateService)}</small>
                <small className="tempo"> às {dateService.getHours()} : {dateService.getMinutes()}</small>
                <small>{props.informacoes.dados.servico.descricao}</small>
            </CardHeader>

            <MusicasBox>
                {componenteMusicas}
                {props.informacoes.modoEdicao &&  <BotaoAdicao clickEvent={HandleClickMusicas}/>}
            </MusicasBox>
            <NovoCardStreaming 
                playlist={props.informacoes.dados.playlist} 
                estaEditando={props.informacoes.modoEdicao}
                clickModal={() => AbrirModal({Editor: "Playlist"})}
            /> 

            <EscalacaoBox>
                <TipoFuncaoBox color={props.informacoes.color}>
                    {componenteVozes}
                    {props.informacoes.modoEdicao &&  <BotaoAdicao clickEvent={HandleClickVoz}/>}
                </TipoFuncaoBox>
                <TipoFuncaoBox color={props.informacoes.color}>
                    {componenteMusicos}
                    {props.informacoes.modoEdicao &&  <BotaoAdicao clickEvent={HandleClickMusicos}/>}
                </TipoFuncaoBox>
            </EscalacaoBox>

            {props.informacoes.modoEdicao && 
                <BoxButtonSalvar>
                    <ButtonSalvarEscala onClick={() => SalvarEscala()}>Salvar Escala</ButtonSalvarEscala>
                </BoxButtonSalvar>
            }

        </NovoCard>
    )
}