import { NovoCardStreamingBox, StreamingButton } from "./style";
import Spotify from "../../Components/Icons/Spotify"
import Youtube from "../Icons/Youtube";

export default function  NovoCardStreaming(props){

    const EstaEditando = props.estaEditando

    return(
        <NovoCardStreamingBox onClick={props.clickModal}>
            <StreamingButton>
                {!EstaEditando ?
                    <a href={props.playlist[0].link} target="_blank">
                        <Spotify height={"3vh"} fillColor={"#fff"}/>
                    </a> :
                    <Spotify height={"3vh"} fillColor={"#fff"}/>
                }
            </StreamingButton>
            <StreamingButton> 
                {!EstaEditando ?
                    <a href={props.playlist[1].link} target="_blank">
                        <Youtube width={"3vh"} fillColor={"#fff"}/>
                    </a> : 
                    <Youtube width={"3vh"} fillColor={"#fff"}/>
                }
            </StreamingButton>
        </NovoCardStreamingBox>
    )
}