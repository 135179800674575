import { FuncaoBox, FuncaoDescritiveBox, IconeBox } from "./style";

export default function NovoCardFuncao(props){
    return (
        <FuncaoBox onClick={props.clickModal}>
            <IconeBox>
                <img id="" src={"https://www.icfcapao.com.br/img/fn_"+props.informacoes.idFuncao+".png"}/>
            </IconeBox>
            <FuncaoDescritiveBox>
                <span>{props.informacoes.nomeMinistro}</span>
                <small>{props.informacoes.nomeFuncao}</small>
            </FuncaoDescritiveBox>
        </FuncaoBox>
    )
}