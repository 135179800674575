import styled from "styled-components";

export const IndividualBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.color};
    min-height: ${(props) => props.height};
    overflow: hidden;
    border-radius: 13px;
    padding: 15px;
    justify-content: space-between; 
`

export const DateBox = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 500;
    font-size: 30px;
`

export const FuncaGroupBox = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 600;
    justify-content: flex-end;

    & small{
        color: rgba(0, 0, 0, 0.4);
    }

`

export const FuncaBox = styled.div`
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #49705D;
`
