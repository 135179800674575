import styled from "styled-components";
import { AppColors } from "../../Styles/colors";

export const ModalBack = styled.div`
    background-color: #fff;
    border-radius: 10px;
    margin: 4vh 8vw;
    padding: 4vh 4vw;
`

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const ModalTitle = styled.h3`
    font-weight: 500;
    font-size: 1.5em;
`

export const ModalDef = styled.p`
    font-weight: 300;
    font-size: 0.8em;
    text-align: justify;
    padding: 1vh 2vw;
`

export const CamposModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    padding: 2vh 0;
`

export const CancelarButton = styled.button`
    background-color: ${AppColors.decorative.greenLight};
    color: ${AppColors.decorative.greenDark};
    border-radius: 5px;
    border: none;
    padding: 1vh 6vw;
    font-weight: 600;
    font-size: 1em;
    box-shadow: 2px 2px 10px -3px rgba(0,0,0,0.75);
`

export const ButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
`